import { Box, CircularProgress, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigation } from 'react-router-dom';
import { mergeSx } from '~/utils/mui';

export const StableWidthLoading = ({
    text,
    altTextNodes,
    width,
    clickTarget,
    sxWrapper,
    sxText,
    sxTextWrapper,
    sxLoading,
    spinnerColor,
    altLoadingComponent,
    overrideLoading,
}: {
    text?: string;
    altTextNodes?: ReactNode;
    width: string;
    clickTarget?: string;
    sxWrapper?: SxProps<Theme>;
    sxText?: SxProps<Theme>;
    sxTextWrapper?: SxProps<Theme>;
    sxLoading?: SxProps<Theme>;
    spinnerColor?: string;
    altLoadingComponent?: ReactNode;
    overrideLoading?: boolean;
}) => {
    const theme = useTheme();

    const navigation = useNavigation();

    let loading = false;
    if (overrideLoading) {
        loading = overrideLoading;
    } else if (clickTarget) {
        const globalNavigationTarget = `${navigation?.location?.pathname}${navigation?.location?.search}`;
        loading = globalNavigationTarget === clickTarget;
    }

    return (
        <Stack sx={mergeSx({ width: width, minWidth: 'fit-content' }, sxWrapper)}>
            <Stack
                sx={mergeSx(
                    { positionwidth: 'fit-content', justifyContent: 'center', alignItems: 'center' },
                    sxTextWrapper,
                )}
            >
                {text && (
                    <Typography
                        sx={mergeSx(
                            {
                                visibility: loading ? 'hidden' : 'visible',
                                fontWeight: 600,
                            },
                            sxText,
                        )}
                    >
                        {text}
                    </Typography>
                )}
                {altTextNodes && (
                    <Box
                        sx={{
                            visibility: loading ? 'hidden' : 'visible',
                            fontWeight: 600,
                        }}
                    >
                        {altTextNodes}
                    </Box>
                )}
                {altLoadingComponent}
                {!altLoadingComponent && (
                    <CircularProgress
                        size={20}
                        sx={mergeSx(
                            {
                                position: 'absolute',
                                color: spinnerColor ?? theme.palette.cta.main,
                                visibility: !loading ? 'hidden' : 'visible',
                            },
                            sxLoading,
                        )}
                    />
                )}
            </Stack>
        </Stack>
    );
};
